
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





























































































.dropdown-input .form-input {
  padding-bottom: 1.7rem;
  background-color: $white;
  border-bottom: 3px solid transparent;

  &:focus-within {
    border-bottom: 3px solid $c-pink-medium;
  }
}

.dropdown-input__dropdown {
  position: absolute;
  z-index: 2;
  top: 100%;
  right: 0;
  left: 0;
  margin-top: 0.9rem;
  padding: $spacing * 1.3 $spacing * 1.5;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 2em 5em rgba(0, 0, 0, 0.15);
}

.dropdown-input__dropdown-link {
  @extend %fw-medium;

  display: block;
  padding: 0.5rem 0;
  text-decoration: none;
  text-transform: capitalize;
  transition: color 0.2s $ease-out;

  &:hover,
  &:focus {
    color: $c-pink-medium;
  }
}
