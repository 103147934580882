
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









































































































































































































































































































































































































































































































































































































































































































































.eligibility-form,
[class*='eligibility-form--'] {
  position: relative;
}

.eligibility__content {
  flex: 1;

  @include mq(l) {
    &:nth-child(2) {
      margin-left: col(1);
    }
  }
}

.eligibility__icon {
  fill: $c-pink-medium;
}

.eligibility__title {
  margin-top: 1rem;
}

.eligibility__txt {
  margin-top: 0;
  margin-bottom: 27px;
  color: $c-gray-darkest;
  line-height: 28px;

  @include mq(m) {
    font-size: 20px;
  }
}

.eligibility__alert {
  position: relative;
  display: block;
  margin-top: 8px;
  padding-left: 25px;
  color: $c-error;
  font-size: 12px;
}

.eligibility__figure {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0;
}

.eligibility__alertinfo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

.eligibility__message {
  margin-bottom: 3rem;
  font-size: 2.4rem;
}

.eligibility__fields {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing * 2;
}

.eligibility__field {
  position: relative;
  width: 100%;
  margin-bottom: $spacing;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    @include mq(m) {
      width: 100%;
    }
  }

  ::v-deep {
    .form-input__label {
      pointer-events: none;
    }
  }
}

.eligibility__final {
  width: 100%;
  margin-bottom: -2rem;

  .eligibility__field {
    width: 100%;

    @include mq(xs) {
      width: calc(34% - 2rem);
    }
  }

  @include mq(xs) {
    display: flex;
    gap: 2rem;
  }
}

.eligibility__input {
  //height: 100%;
  padding-bottom: 1.7rem;
  background-color: $white;
  border-bottom: 3px solid transparent;

  &:focus-within {
    border-bottom: 3px solid $c-pink-medium;
  }
}

.eligibility__info {
  color: $c-blue-pale;
  font-family: $ff-alt;
  font-size: 10px;
  font-weight: 600;
  line-height: 23px;
  text-transform: uppercase;
}

.eligibility__field--select {
  ::v-deep {
    svg {
      fill: $c-pink-medium;
    }

    .vs__dropdown-toggle {
      @extend %box-shadow;

      position: relative;
      padding: $spacing * 1.25 $spacing;
      background-color: $white;
      border: 0;
      border-radius: 4px;
    }

    .vs__search,
    .vs__actions,
    .vs__selected,
    .vs__selected-options {
      margin: 0;
      padding: 0;
      border: 0;
    }

    .vs__actions {
      line-height: 1;
    }

    .vs__clear {
      margin-bottom: 0.3rem;
    }

    .vs__search {
      @extend %fw-bold;

      padding: 0.2rem 0;
    }

    .vs__search:focus {
      /* stylelint-disable declaration-no-important */
      margin: 0 !important;
      padding: 0.2rem 0 !important;
      border: 0 !important;
      /* stylelint-enable declaration-no-important */
    }

    .vs__selected {
      @extend %fw-bold;

      border-radius: 0;
    }
  }
}

.eligibility__select {
  @extend %box-shadow;

  position: relative;
  padding: $spacing * 1.25 $spacing;
  border-radius: 4px;

  select {
    @extend %fw-bold;

    width: 100%;
    font-family: $ff-alt;
    border: 0;
  }
}

.eligibility__success {
  .eligibility__cta:nth-child(even) {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid $c-gray;

    .btn {
      color: $c-blue-light;
      background: none;
      border: 1px solid $c-blue-light;

      ::v-deep {
        .btn__icon svg {
          fill: $c-blue-light;
        }

        .btn__link__title {
          color: $c-blue-light;
        }
      }
    }
  }
}

.eligibility__back {
  margin-top: 2rem;
}

.eligibility__action {
  a {
    margin-left: $spacing;
  }
}
