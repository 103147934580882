
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        































































.eligibility-banner,
[class*='eligibility-banner--'] {
  position: relative;
  padding: 3.4rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $c-blue-medium;
  border-radius: 8px;

  @include mq(s) {
    max-width: 850px;
    margin: 0 auto;
  }
}

.eligibility-banner__box {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.eligibility-banner__content {
  flex: 1;

  @include mq(m) {
    margin-right: $spacing * 2;
  }
}

.eligibility-banner__title {
  margin-top: 0;
  margin-bottom: 0.6rem;
  color: $white;

  strong {
    color: $c-pink-medium;
  }

  @include mq(m) {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.eligibility-banner__tag {
  position: absolute;
  z-index: 3;
  top: -15px;
  left: 2.8rem;

  strong {
    @extend %fw-bold;
  }

  @include mq('s') {
    left: 4.7rem;
  }
}

.eligibility-banner__icon {
  position: relative;
  fill: white;

  @include mq($until: m) {
    display: none;
  }
}

.eligibility-banner__image {
  @include get-all-space;

  img {
    @include image-fit;

    border-radius: 8px;

    [class*='--full'] & {
      @include get-all-space;

      object-fit: cover;
      border-radius: 0;
    }
  }

  @include mq($until: m) {
    display: none;
  }
}

.eligibility-banner__action__btn {
  margin-top: $spacing * 0.75;
}

.eligibility-banner__action {
  @include mq($until: m) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.eligibility-banner__action__btn + .eligibility-banner__action__link {
  margin-top: $spacing * 0.5;

  @include mq(m) {
    margin-top: 0;
    margin-left: $spacing * 1.5;
  }
}
